@import "styles/common";

.techStack {
  h3 {
    margin-bottom: 33px;
    text-align: center;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    grid-gap: 30px;
    justify-content: center;
    padding: 10px;
    margin: 0 -10px;

    @include mobile {
      grid-template-columns: repeat(auto-fill, 135px);
      grid-gap: 30px 15px;
    }

    .tech {
      height: 160px;
      border: 1px solid $dark;
      border-radius: 1px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;

      @include mobile {
        height: 135px;
      }

      .name {
        text-align: center;
        padding: 0 10px;
        background-color: white;
        bottom: -9px;
        position: absolute;
        transition: 0.3s;
      }

      img {
        transform: scale(1);
        transition: 0.3s;
      }

      &:hover {
        .name {
          opacity: 1;
        }

        img {
          transform: scale(1.15);
        }
      }
    }
  }
}
