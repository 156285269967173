@import "styles/common";

.header {
  top: 0;
  background-color: $dark;
  position: sticky;
  z-index: 10;
  padding: 0 $container_padding_tablet;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 80px;

  @include mobile {
    padding: 0 $container_padding_mobile;
  }

  .logo {
    color: white;
  }

  .menu {
    display: flex;
    align-items: center;
    overflow: auto;

    @include mobile {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      color: white;
      text-transform: uppercase;
      white-space: nowrap;
      text-decoration: none;

      & + .item {
        margin-left: 32px;
      }

      &.lang {
        appearance: none;
        padding: 5px 15px;
        border: 1px solid white;
        border-radius: 2px;
        background-color: transparent;
        color: white;
        font-size: 12px;
        user-select: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        width: 50px;
      }
    }
  }

  .burger {
    cursor: pointer;
    display: none;

    @include mobile {
      display: block;
    }

    .mobileMenu {
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: calc(100vh - #{$header_height});
      background-color: $dark;
      position: fixed;
      top: $header_height;
      transition: 0.3s;
      padding: 0 10px;
      left: 100%;
      z-index: 10;

      .item {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        text-decoration: none;

        &.lang {
          justify-content: space-between;
          border-top: 1px solid rgba(white, 0.7);
          margin-top: 20px;
          cursor: pointer;
        }

        .lang {
          width: 50px;
          appearance: none;
          padding: 5px 15px;
          border: 1px solid white;
          border-radius: 2px;
          background-color: transparent;
          color: white;
          font-size: 12px;
          user-select: none;
          box-shadow: none;
          outline: none;
        }
      }
    }

    .image {
      &.open {
        display: block;
      }

      &.close {
        display: none;
      }
    }

    &.open {
      .mobileMenu {
        left: 0;
      }

      .image {
        &.open {
          display: none;
        }

        &.close {
          display: block;
        }
      }
    }
  }
}
