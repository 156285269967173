@import "styles/common";

.softwareDevelopment {
  .inner {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .main {
    align-items: center;
    min-height: calc(100vh - #{$header_height});
    display: flex;
    background-image: url("./images/main-banner.jpg");
    background-size: cover;
    background-position: center;
    position: relative;

    &:before {
      background-color: rgba(black, 0.75);
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      width: 100%;
    }

    .mainInner {
      position: relative;

      h1 {
        color: white;

        strong {
          color: $orange;
        }

        @include mobile {
          text-align: center;
          font-size: 13vw;
          line-height: 14vw;
        }
      }
    }
  }

  .distributedFirst {
    background-color: $light;

    .distributedFirstInner {
      h3 {
        text-align: center;
      }
    }
  }

  .workflow {
    .workflowInner {
      padding-top: 60px;

      h3 {
        text-align: center;
      }

      .items {
        margin-top: 63px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .item {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 235px;
          margin: 0 10px 40px;

          .image {
            height: 235px;

            @include mobile {
              height: initial;
            }

            img {
              width: 100%;
            }
          }

          .text {
            margin: 23px 0 8px;
            text-align: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;

            @include tablet {
              margin: 19px 0 8px;
            }

            @include mobile {
              margin: 10px 0 8px;
            }
          }

          .description {
            text-align: center;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
