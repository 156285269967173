@import "styles/common";

.navLink {
  position: relative;
  color: $dark;
  line-height: 13px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: 0.5s;

  &:hover {
    color: red;
  }
}

.active {
  color: red;
}
