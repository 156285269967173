@import "styles/common";

.textarea {
  box-shadow: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  padding-bottom: 16px;

  textarea {
    font-size: inherit;
    font-weight: inherit;
    outline: none;
    font-size: 16px;
    box-shadow: none;
    line-height: 20px;
    font-weight: 400;
    transition: $transition;
    position: relative;

    border: 1px solid $dark;
    background-color: white;
    padding: 13px;
    height: 140px;
    resize: none;
    width: 100%;

    &::placeholder {
      color: rgba(0, 0, 0, 0.36);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: black;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      color: rgba($dark, 0.75);
    }
  }

  label {
    @extend %fieldlabel;
  }

  .error {
    @extend %fieldError;
  }

  &.withValue {
    padding-top: 0;
  }

  &.withError {
    padding-bottom: 0;
  }
}
