@import "styles/common";

.workWithUs {
  h3 {
    margin-bottom: 29px;
    text-align: center;
  }

  form {
    margin: 0 -15px;
    display: flex;

    @include mobile {
      margin: 0;
      flex-direction: column;
    }

    .col {
      width: 50%;
      margin: 0 15px;
      display: flex;
      flex-direction: column;

      @include mobile {
        margin: 0;
        width: 100%;
      }

      .field {
        @include mobile {
          margin-top: 20px;
        }

        & + .field {
          margin-top: 4px;
        }
      }
    }
  }

  p {
    font-size: 18px;
    margin-top: 20px;
  }
}
