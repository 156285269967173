@import "styles/common";

.home {
  .inner {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .main {
    align-items: center;
    min-height: calc(100vh - #{$header_height});
    display: flex;
    background-image: url("./images/main-banner.jpg");
    background-size: cover;
    background-position: center;
    position: relative;

    &:before {
      background-color: rgba(black, 0.75);
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      width: 100%;
    }

    .mainInner {
      position: relative;

      h1 {
        color: white;

        strong {
          color: $orange;
        }

        @include mobile {
          text-align: center;
          font-size: 13vw;
          line-height: 14vw;
        }
      }
    }
  }

  .whoWeAre {
    .whoWeAreInner {
      display: flex;

      @include mobile {
        flex-direction: column;
      }

      .image {
        $indent: 15px;

        height: 540px;
        width: 540px;
        max-width: 50%;
        flex-shrink: 0;
        position: relative;
        margin-right: 125px;
        padding: $indent $indent 0 0;

        @include tablet {
          width: 380px;
          margin-right: 50px;
        }

        @include mobile {
          display: none;
          // width: 100%;
          // max-width: initial;
          // margin-right: 0;
        }

        img {
          // height: calc(100% - #{$indent});
          // width: calc(100% - #{$indent});
          height: 100%;
          width: 100%;
          object-fit: cover;
          position: relative;
          z-index: 2;
        }

        &:before {
          content: "";
          height: calc(100% - #{$indent});
          width: calc(100% - #{$indent});
          position: absolute;
          right: 0;
          top: 0;
          background-color: $orange;
          z-index: 1;
        }
      }

      .info {
        h3 {
          margin-bottom: 17px;
        }

        h4 {
          margin-bottom: 15px;
        }

        fieldset {
          @include mobile {
            border: 1px solid $dark;
            padding: 20px;
          }

          legend {
            @include mobile {
              margin: auto;
              padding: 0 5px;

              h3 {
                margin: 0;
              }
            }
          }

          ul {
            margin-bottom: 15px;

            li {
              & + li {
                margin-top: 10px;
              }
            }
          }

          p.text {
            color: $orange;
          }
        }
      }
    }
  }

  .services {
    background-color: $light;

    .servicesInner {
      h3 {
        margin-bottom: 29px;
        text-align: center;
      }

      .swiperContainer {
        .slider {
          margin-bottom: 45px;

          .slide {
            .name {
              align-items: center;
              display: flex;
              font-weight: 600;
              margin-bottom: 15px;

              @include tablet {
                min-height: 36px;
              }

              @include mobile {
                min-height: 47px;
              }

              .caret {
                height: 0;
                width: 0;
                display: inline-block;
                border-left: 10px solid $orange;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                margin-right: 10px;
              }
            }

            .image {
              width: 100%;

              img {
                width: 100%;
                object-fit: cover;
                height: 350px;

                @include tablet {
                  height: 350px;
                }

                @include mobile {
                  height: 280px;
                }
              }
            }

            button {
              width: 100%;
              height: 50px;
            }
          }
        }
      }

      .controls {
        display: flex;
        width: 300px;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        position: relative;
        justify-content: center;

        @include tablet {
          // width: 200px;
        }

        .buttonPrev {
          margin-right: 30px;
        }

        .buttonPrev,
        .buttonNext {
          position: static;

          &:after {
            content: "";
          }
        }
      }
    }
  }

  .projects {
    .projectsInner {
      h3 {
        margin-bottom: 29px;
        text-align: center;
      }

      .swiperContainer {
        .swiperWrapper {
          margin-bottom: 45px;

          .swiperSlide {
            .service {
              display: flex;
              flex-direction: column;
              width: 100%;

              // & + .service {
              //   margin-left: 20px;
              // }

              .image {
                height: 240px;
                border: 1px solid white;
                width: 100%;

                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }

              .name {
                margin-top: 15px;
              }
            }
          }
        }
      }

      .controls {
        display: flex;
        width: 300px;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        position: relative;
        justify-content: center;

        @include tablet {
          // width: 200px;
        }

        .buttonPrev {
          margin-right: 30px;
        }

        .buttonPrev,
        .buttonNext {
          position: static;

          &:after {
            content: "";
          }
        }
      }
    }
  }
}
