@import "styles/common";

footer {
  background-color: rgba($dark, 0.9);
  color: white;

  .container {
    .container--inner {
      display: flex;

      @include mobile {
        flex-direction: column;
      }

      .block {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 80px 0;

        @include mobile {
          padding: 35px 0;
        }

        .items {
          display: flex;
          flex-wrap: wrap;
          margin-top: 30px;

          @include tablet {
            flex-direction: column;
          }

          .item {
            align-items: center;
            display: flex;
            width: 100%;
            margin-top: 20px;

            @include tablet {
              margin-top: 0;
            }

            @include tablet {
              width: 100%;

              & + .item {
                margin-top: 20px;
              }
            }

            img {
              margin-right: 30px;
            }

            div {
              display: inline-block;
              word-wrap: break-word;
              max-width: 70%;
            }
          }
        }
      }
    }

    &--copyright {
      background-color: $dark;

      .container--inner {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
