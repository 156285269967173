@import "styles/common";

.container {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1110px;

  @include mobile {
    padding: 0 10px;
  }

  // &.inner {
  //   max-width: 1100px;
  //   margin: 0 auto;
  // }

  &.full {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
}
