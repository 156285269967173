.Toastify {
  font-size: 14px;
  font-weight: 500;

  // .Toastify__toast-container {
  // }
  .Toastify__toast {
    color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    border-radius: 4px;
    padding: 11px 14px;
    min-height: 38px;
    box-shadow: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    max-width: 290px;

    .Toastify__toast-body {
      display: flex;
      padding-top: 2px;

      &:before {
        transform: scale(1.1);
        margin: 2px 10px 0 0;
      }
    }

    &.Toastify__toast--error {
      background-color: #fc5558;

      .Toastify__toast-body:before {
        // content: url("/images/svg/toast-error.svg");
      }
    }

    &.Toastify__toast--warning {
      background-color: #f7743e;

      .Toastify__toast-body:before {
        // content: url("/images/svg/toast-warning.svg");
      }
    }

    &.Toastify__toast--success {
      background-color: #32bea6;

      .Toastify__toast-body:before {
        // content: url("/images/svg/toast-success.svg");
      }
    }

    &.Toastify__toast--info {
      background-color: #3a7bdb;

      .Toastify__toast-body:before {
        // content: url("/images/svg/toast-info.svg");
      }
    }
  }

  .Toastify__close-button {
    align-self: initial;
    margin-top: 1px;
    opacity: 1;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  // .Toastify__progress-bar {
  // }
}
