%fieldlabel {
  color: $dark;
  font-size: 13px;
  line-height: 13px;
  opacity: 0.75;
  display: block;
  height: 13px;
}

%fieldError {
  line-height: 16px;
  color: red;
  margin-top: 1px;
  font-size: 12px;
  letter-spacing: 0.25px;
}
