@import "styles/common";

@import "~react-toastify/dist/ReactToastify.css";
@import "~swiper/swiper.scss";

@import "custom/toast.scss";

html {
  scroll-behavior: smooth;
  will-change: scroll-position;
}

body {
  margin: 0;
  color: $dark;
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato", "sans-serif";
  font-feature-settings: "calt", "liga";
  font-variation-settings: "wght" 400;
  font-variation-ligatures: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 100px;
  line-height: 120px;
  font-weight: 300;

  @include tablet {
    font-size: 80px;
    line-height: 96px;
  }

  @include mobile {
    font-size: 40px;
    line-height: 48px;
  }
}

h2 {
  font-size: 100px;
  line-height: 120px;

  @include tablet {
    font-size: 80px;
    line-height: 96px;
  }

  @include mobile {
    font-size: 40px;
    line-height: 48px;
  }
}

h3 {
  // font-weight: 300;
  font-size: 48px;
  line-height: 58px;

  @include tablet {
    font-size: 36px;
    line-height: 42px;
  }

  @include mobile {
    font-size: 30px;
    line-height: 36px;
  }
}

h4 {
  // font-weight: 300;
  font-size: 24px;
  line-height: 30px;

  @include tablet {
    font-size: 20px;
    line-height: 24px;
  }

  @include mobile {
    font-size: 18px;
    line-height: 22px;
  }
}

p {
  font-size: 24px;
  line-height: 24px;

  @include tablet {
    font-size: 18px;
    line-height: 18px;
  }

  // @include mobile {
  //   font-size: 24px;
  //   line-height: 24px;
  // }

  [data-small] {
    font-size: 18px;
    line-height: 22px;

    @include tablet {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.text {
  font-size: 18px;
  line-height: 22px;
  // font-weight: 300;

  @include tablet {
    font-size: 16px;
    line-height: 20px;
  }
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

button {
  background-color: $orange;
  height: 60px;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 0;
  font-weight: 600;
  border: 0;
  color: white;
  transition: 0.3s;
  cursor: pointer;
  outline: none;

  @include tablet {
    font-size: 16px;
  }

  &:not([disabled]):hover {
    background-color: $dark;
  }

  &[disabled] {
    color: rgba($dark, 0.4);
    cursor: not-allowed;
    background-color: $light;
  }
}

label {
  font-size: 16px;

  @include tablet {
    font-size: 14px;
  }
}

.container {
  width: 100%;
  padding: 0 20px;
  margin: 0;

  @include mobile {
    padding: 0 10px;
  }

  &--inner {
    max-width: 1100px;
    margin: auto;
  }
}

// input {
//   height: 60px;
//   border: 1px solid $dark;
//   background-color: white;
//   padding: 0 13px;
//   position: relative;
//   width: 100%;

//   &::placeholder {
//     color: rgba(0, 0, 0, 0.36);
//   }

//   &:before {
//     position: absolute;
//     // content: attr(data-message);
//     content: "";
//     bottom: -10px;
//     left: 0;
//     color: red;
//   }
// }

// textarea {
//   border: 1px solid $dark;
//   background-color: white;
//   padding: 13px;
//   height: 140px;
//   resize: none;
//   width: 100%;

//   &::placeholder {
//     color: rgba(0, 0, 0, 0.36);
//   }
// }

.caret {
  height: 0;
  width: 0;
  display: inline-block;
  border-left: 10px solid $orange;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

ul {
  li {
    padding-left: 22px;
    position: relative;

    .caret {
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
}
