@import "styles/common";

.outstaffing {
  .inner {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .main {
    align-items: center;
    min-height: calc(100vh - #{$header_height});
    display: flex;
    background-image: url("./images/main-banner.jpg");
    background-size: cover;
    background-position: center;
    position: relative;

    &:before {
      background-color: rgba(black, 0.75);
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      width: 100%;
    }

    .mainInner {
      position: relative;

      h1 {
        color: white;

        strong {
          color: $orange;
        }

        @include mobile {
          text-align: center;
          font-size: 13vw;
          line-height: 14vw;
        }
      }
    }
  }

  .teamIsRight {
    .teamIsRightInner {
      padding-top: 40px;
      padding-bottom: 40px;

      .thanks {
        display: flex;
        display: none;

        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
          text-align: center;
          margin-bottom: 74px;
        }

        .image {
          display: flex;
        }
      }

      .slider {
        width: 100%;
        overflow: hidden;

        .slides {
          align-items: flex-start;
          display: flex;
          height: 665px;
          width: 100%;

          @include tablet {
            height: 820px;
          }

          @include tablet {
            height: 648px;
          }

          &.active {
            transition: 0.3s;

            &-1 {
            }
            &-2 {
              transform: translateX(-100%);
            }
            &-3 {
              transform: translateX(-200%);
            }
          }

          .slide {
            height: 100%;
            min-width: 100%;
            // overflow: auto;

            h3 {
              // margin-bottom: 33px;
              text-align: center;
              height: 73px;
            }

            &.slide1 {
              .selects {
                padding: 20px 0;
                overflow: auto;
                height: 435px;

                @include tablet {
                  height: 645px;
                }

                @include mobile {
                  height: 540px;
                }
              }

              .row {
                display: flex;

                @include mobile {
                  flex-wrap: wrap;
                }

                & + .row {
                  margin-top: 20px;
                }

                select {
                  flex: 1;
                  height: 60px;
                  border: 1px solid $dark;
                  padding: 0 15px;
                  min-width: 100px;

                  @include tablet {
                    height: 50px;
                  }

                  @include mobile {
                    height: 40px;

                    &:nth-child(1) {
                      flex: 100%;
                      margin-bottom: 5px;
                    }

                    &:nth-child(2) {
                      flex: calc(50% - 10px);
                    }

                    &:nth-child(3) {
                      margin-left: 10px;
                      flex: calc(50% - 10px);

                      @include mobile {
                        // margin: 5px 0 0;
                      }
                    }
                  }

                  & + select {
                    margin-left: 30px;

                    @include mobile {
                      margin-left: 0;
                    }
                  }
                }

                &.add {
                  display: flex;
                  justify-content: center;

                  button {
                    height: 60px;
                    font-size: 40px;
                    width: calc(100% / 3 - 20px);

                    @include tablet {
                      height: 50px;
                    }

                    @include mobile {
                      font-size: 30px;
                      height: 40px;
                      width: 100%;
                    }
                  }
                }

                &.title {
                  @include mobile {
                    display: none;
                  }

                  div {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    color: white;
                    background-color: $dark;
                    height: 60px;
                    padding: 0 20px;

                    @include tablet {
                      height: 50px;
                    }

                    @include mobile {
                      height: 40px;
                    }

                    & + div {
                      margin-left: 30px;
                    }
                  }
                }
              }
            }

            &.slide2 {
              .locations {
                display: flex;

                @include tablet {
                  flex-direction: column;
                  max-width: 600px;
                  margin: auto;
                }

                .locationsAddress {
                  flex: 1;

                  .header {
                    display: flex;
                    height: 70px;
                    background-color: $light;

                    @include tablet {
                      height: 60px;
                    }

                    @include mobile {
                      height: initial;
                      flex-direction: column;
                    }

                    .item {
                      flex: 1;
                      align-items: center;
                      display: flex;
                      justify-content: center;
                      padding: 0 35px;
                      cursor: pointer;

                      @include mobile {
                        flex: initial;
                        height: 35px;
                        flex-direction: column;
                      }

                      &--active {
                        background-color: $dark;
                        color: white;
                      }
                    }
                  }

                  .content {
                    @include tablet {
                      height: 280px;
                    }

                    @include mobile {
                      height: 170px;
                    }

                    .image {
                      width: 100%;
                      padding-top: 100%;
                      background-color: $light;
                      display: none;
                      position: relative;
                      overflow: hidden;

                      @include tablet {
                        padding-top: 0;
                        height: 100%;
                      }

                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                      }

                      .block {
                        bottom: -230px;
                        left: -70px;
                        position: absolute;
                        width: 300px;
                        height: 300px;
                        background-color: $orange;
                        z-index: 1;
                        transform: rotateZ(45deg);
                      }

                      .address {
                        color: white;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 2;
                        font-size: 18px;
                        line-height: 22px;
                        padding: 10px;
                      }

                      &--active {
                        display: block;
                      }
                    }
                  }
                }

                .or {
                  font-size: 48px;
                  font-weight: 600;
                  color: $orange;
                  margin: 0 80px;
                  align-self: center;

                  @include tablet {
                    font-size: 30px;
                    margin: 20px 0 25px;
                  }

                  @include tablet {
                    font-size: 18px;
                  }
                }

                .locationRemote {
                  flex: 1;

                  .header {
                    display: flex;
                    height: 70px;
                    background-color: $light;

                    @include tablet {
                      height: 60px;
                    }

                    @include mobile {
                      height: initial;
                    }

                    .item {
                      flex: 1;
                      align-items: center;
                      display: flex;
                      justify-content: center;
                      padding: 0 35px;
                      cursor: pointer;

                      @include mobile {
                        flex: initial;
                        height: 35px;
                        flex-direction: column;
                      }

                      &--active {
                        background-color: $dark;
                        color: white;
                      }
                    }
                  }

                  .content {
                    @include tablet {
                      height: 280px;
                    }

                    @include tablet {
                      height: 170px;
                    }

                    .image {
                      width: 100%;
                      padding-top: 100%;
                      background-color: $light;
                      position: relative;
                      overflow: hidden;

                      @include tablet {
                        padding-top: 0;
                        height: 100%;
                      }

                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                      }

                      .block {
                        bottom: -230px;
                        right: -70px;
                        position: absolute;
                        width: 300px;
                        height: 300px;
                        background-color: $orange;
                        z-index: 1;
                        transform: rotateZ(45deg);
                      }

                      .address {
                        text-align: right;
                        color: white;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        z-index: 2;
                        font-size: 18px;
                        line-height: 22px;
                        padding: 10px;
                      }
                    }
                  }
                }
              }
            }

            &.slide3 {
              .contactForm {
                display: flex;

                .col {
                  flex: 1;

                  @include tablet {
                    max-width: 450px;
                    margin: auto;
                  }

                  & + .col {
                    margin-left: 70px;

                    @include tablet {
                      display: none;
                    }
                  }

                  .form {
                    display: flex;
                    flex-direction: column;

                    input {
                      margin-bottom: 20px;
                    }

                    textarea {
                      margin-bottom: 20px;
                      height: 160px;
                    }
                  }

                  .image {
                    padding-top: 100%;
                    width: 100%;
                    position: relative;
                    right: 15px;

                    .background {
                      position: absolute;
                      top: -15px;
                      right: -15px;
                      height: 100%;
                      width: 100%;
                      background-color: $orange;
                    }

                    img {
                      top: 0;
                      left: 0;
                      height: 100%;
                      width: 100%;
                      object-fit: cover;
                      position: absolute;
                    }
                  }
                }
              }
            }
          }
        }

        .controls {
          align-items: center;
          display: flex;
          width: calc(100% / 3 - 20px);
          margin: auto;
          font-weight: 600;
          font-size: 18px;
          margin-top: 35px;
          padding: 0 30px;

          @include tablet {
            width: 100%;
            max-width: 320px;
          }

          @include mobile {
            padding: 0 10px;
          }

          .prev,
          .next {
            align-items: center;
            display: flex;
            cursor: pointer;

            &[disabled="true"] {
              cursor: not-allowed;
              opacity: 0.5;
            }
          }

          .prev {
            img {
              margin-right: 10px;
            }
          }

          .current {
            margin: auto;
          }

          .next {
            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
