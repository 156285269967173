@import "styles/common";

.legacySoftware {
  .inner {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .main {
    align-items: center;
    min-height: calc(100vh - #{$header_height});
    display: flex;
    background-image: url("./images/main-banner.jpg");
    background-size: cover;
    background-position: center;
    position: relative;

    &:before {
      background-color: rgba(black, 0.75);
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      width: 100%;
    }

    .mainInner {
      position: relative;

      h1 {
        color: white;

        strong {
          color: $orange;
        }

        @include mobile {
          text-align: center;
          font-size: 13vw;
          line-height: 14vw;
        }
      }
    }
  }

  .ifYouAre {
    .ifYouAreInner {
      h3 {
        text-align: center;
      }

      .items {
        display: flex;

        @include mobile {
          flex-direction: column;
        }

        .item {
          width: calc(100% / 3 - 20px);
          display: flex;
          flex-direction: column;

          @include mobile {
            width: auto;
            max-width: 400px;
            min-width: 280px;
            margin: 0 auto;
          }

          & + .item {
            margin-left: 30px;

            @include mobile {
              margin: 25px auto 0;
            }
          }

          .image {
            width: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .title {
            font-size: 30px;
            margin-top: 20px;
            text-align: center;

            @include tablet {
              font-size: 24px;
            }

            @include tablet {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .softwareRedesign {
    .softwareRedesignInner {
      h3 {
        text-align: center;
        color: $orange;
        text-transform: uppercase;
      }

      .items {
        display: grid;
        margin-top: 88px;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 63px 30px;
        justify-content: space-between;

        @include tablet {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 50px 30px;
        }

        @include mobile {
          grid-template-columns: 1fr;
          grid-gap: 40px 30px;
        }

        .item {
          border: 1px solid $light;
          padding: 20px 30px 20px 90px;
          position: relative;
          transition: 0.3s;

          @include tablet {
            padding-left: 60px;
          }

          @include mobile {
            padding-left: 20px;
          }

          .number {
            font-size: 100px;
            color: $orange;
            top: -52px;
            left: 15px;
            font-weight: 600;
            position: absolute;
            background-color: white;
            transition: 0.3s;

            @include tablet {
              font-size: 75px;
            }

            @include mobile {
              font-size: 40px;
              left: 50%;
              transform: translateX(-50%);
              margin: auto;
              top: -25px;
              padding: 0 10px;
            }
          }

          &:hover {
            border-color: darken($light, 15%);

            .number {
              transform: scale(1.1);

              @include mobile {
                transform: translateX(-50%) scale(1.1);
              }
            }
          }
        }
      }
    }
  }

  .impact {
    background-color: $light;

    .impactInner {
      h3 {
        text-align: center;
      }

      .charts {
        display: flex;
        margin-top: 62px;

        @include tablet {
          flex-direction: column;
          margin-top: 30px;
        }

        .chartWrap {
          padding-bottom: 22px;
          height: 220px;
          width: calc(100% / 3);

          @include tablet {
            width: 100%;
            height: 285px;
            max-width: 400px;
            margin: 0 auto 35px;
          }

          @include mobile {
            width: 100%;
            height: 210px;
            max-width: 400px;
          }

          & + .chartWrap {
            margin-left: 38px;

            @include tablet {
              margin: 0 auto 35px;
            }
          }
        }

        .chart {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          position: relative;
          padding-bottom: 30px;
          padding-left: 45px;

          .title {
            position: absolute;
            bottom: -22px;
            font-size: 16px;
            height: 22px;
            left: 0;
            right: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center;
          }

          .data {
            background-image: repeating-linear-gradient(
              rgba($dark, 0.05),
              rgba($dark, 0.05) 1px,
              transparent 1px,
              transparent 20%
            );
            // background-size: 2px 5px;

            background-clip: content-box;
            background-origin: content-box;
            padding-top: 20px;
            padding-right: 20px;
            flex: 1;
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            position: relative;

            .arrow {
              position: absolute;

              &:after,
              &:before {
                position: absolute;
                content: "";
                height: 1px;
                width: 10px;
                background-color: $dark;
              }

              &.arrowTop {
                top: 0;
                left: 0;

                &:after {
                  transform: rotateZ(55deg);
                  left: -3px;
                  top: 3px;
                }

                &:before {
                  transform: rotateZ(-55deg);
                  left: -8px;
                  top: 3px;
                }
              }

              &.arrowLeft {
                bottom: 0;
                right: 0;

                &:after {
                  transform: rotateZ(215deg);
                  bottom: 2px;
                  right: -1px;
                }

                &:before {
                  transform: rotateZ(-215deg);
                  bottom: -4px;
                  right: -1px;
                }
              }
            }

            .candle {
              width: 5px;
              background-color: $orange;
              border-radius: 5px 5px 0 0;
            }
          }

          .axis {
            position: absolute;

            &.axisX {
              padding-right: 20px;
              display: flex;
              bottom: 0;
              right: 0;
              width: calc(100% - 45px);

              .units {
                flex: 1;
                display: flex;
                justify-content: space-around;

                div {
                  font-size: 14px;
                }
              }
            }

            &.axisY {
              padding-top: 20px;
              display: flex;
              top: 0;
              left: 0;
              height: calc(100% - 30px);

              .units {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                justify-content: space-between;

                div {
                  position: relative;
                  display: flex;
                  flex: 1;
                  font-size: 14px;
                  top: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
