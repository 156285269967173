// colors

$orange: #f16500;
$dark: #0b0b0b;
$light: #f3f3f3;

// variables

$header_height: 80px;

$container_padding_mobile: 10px;
$container_padding_tablet: 20px;
$container_padding_laptop: 20px;
$container_padding_desktop: 20px;

$transition: 0.3s;
